import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React from "react";
import styled from "styled-components";
import topBackground from "../images/background.png";
import background from "../images/billdingBackground.png";
import owl from "../images/jujirou.png";
import logo from "../images/logo.svg";
import cat from "../images/neko.png";
import { default as Seo } from "../layouts/head";
const Release = () => {
  let test = true;
  let countdown = setInterval(function () {
    const now = new Date(); //今の日時
    const target = new Date(
      now.getFullYear(),
      now.getMonth(),
      19,
      "14",
      "00",
      "00"
    ); //ターゲット日時を取得
    const remainTime = target - now; //差分を取る（ミリ秒で返ってくる

    //指定の日時を過ぎていたら処理をしない
    if (remainTime < 0 && test && window !== "undefined") {
      window.location.href = process.env.GATSBY_SITE_URL;
      test = false;
    }

    if (remainTime < 0) return false;

    //差分の日・時・分・秒を取得
    const difDay = Math.floor(remainTime / 1000 / 60 / 60 / 24);
    const difHour = Math.floor(remainTime / 1000 / 60 / 60) % 24;
    const difMin = Math.floor(remainTime / 1000 / 60) % 60;
    const difSec = Math.floor(remainTime / 1000) % 60;

    //残りの日時を上書き
    document.getElementById("countdown-day").textContent = difDay;
    document.getElementById("countdown-hour").textContent = difHour;
    document.getElementById("countdown-min").textContent = difMin;
    document.getElementById("countdown-sec").textContent = difSec;

    //指定の日時になればカウントを止める
    if (remainTime < 0) {
      clearInterval(countdown);
    }
  }, 1000);
  const breakpoints = useBreakpoint();

  return (
    <>
      <Seo title={`ロートリ カウントダウン`} />
      {breakpoints.sm ? (
        <MainDiv id="countdownArea" class="countdown">
          <Div>
            <LogoDiv>
              <Logo src={logo} alt="logo" issp={true} />
            </LogoDiv>
            <TextDiv>
              <Text>リリースまで...</Text>
            </TextDiv>
            <TimerDiv issp={true}>
              <OwlDiv issp={true}>
                <OwlImg src={owl} alt="logo" issp={true} />
                <Box issp={true}>
                  <TimeSpan issp={true} id="countdown-day" />
                  <TimeTextSpan issp={true}>days</TimeTextSpan>
                </Box>
              </OwlDiv>
              <Box issp={true}>
                <TimeSpan issp={true} id="countdown-hour" />
                <TimeTextSpan issp={true}>hours</TimeTextSpan>
              </Box>
              <Box issp={true}>
                <TimeSpan issp={true} id="countdown-min" />
                <TimeTextSpan issp={true}>mins</TimeTextSpan>
              </Box>
              <div>
                <CatImg src={cat} alt="logo" issp={true} />
                <Box issp={true}>
                  <TimeSpan issp={true} id="countdown-sec" />
                  <TimeTextSpan issp={true}>secs</TimeTextSpan>
                </Box>
              </div>
            </TimerDiv>
          </Div>
          <BackGroundDiv>
            <BackGround src={background} />
          </BackGroundDiv>
        </MainDiv>
      ) : (
        <MainDiv id="countdownArea" class="countdown">
          <Div>
            <LogoDiv>
              <Logo src={logo} alt="logo" />
            </LogoDiv>
            <TextDiv>
              <Text>リリースまで...</Text>
            </TextDiv>
            <TimerDiv>
              <OwlDiv>
                <OwlImg src={owl} alt="logo" />
                <Box>
                  <TimeSpan id="countdown-day" />
                  <TimeTextSpan>days</TimeTextSpan>
                </Box>
              </OwlDiv>
              <Box>
                <TimeSpan id="countdown-hour" />
                <TimeTextSpan>hours</TimeTextSpan>
              </Box>
              <Box>
                <TimeSpan id="countdown-min" />
                <TimeTextSpan>mins</TimeTextSpan>
              </Box>
              <div>
                <CatImg src={cat} alt="logo" />
                <Box>
                  <TimeSpan id="countdown-sec" />
                  <TimeTextSpan>secs</TimeTextSpan>
                </Box>
              </div>
            </TimerDiv>
          </Div>
          <BackGroundDiv>
            <BackGround src={background} />
          </BackGroundDiv>
        </MainDiv>
      )}
    </>
  );
};

const MainDiv = styled.div`
  background-image: url(${topBackground});
  display: block;
  font-family: "Noto Sans JP", "sans-serif";
  height: 100vh;
  overflow: hidden;
`;

const Div = styled.div`
  z-index: 99;
  position: absolute;
  top: calc(50% - 190px);
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LogoDiv = styled.div`
  padding-top: 100px;
  text-align: center;
`;
const Logo = styled.img`
  width: ${(props) => (props.issp ? `200px` : `283px`)}; ;
`;

const OwlDiv = styled.div`
  width: ${(props) => (props.issp ? `80px` : `190px`)};
`;

const OwlImg = styled.img`
  z-index: 1;
  right: ${(props) => (props.issp ? `0px` : `-35px`)};
  width: ${(props) => (props.issp ? `80px` : `120px`)};
  position: relative;
  top: ${(props) => (props.issp ? `15px` : `19px`)};
`;

const CatImg = styled.img`
  position: relative;
  top: 12px;
  width: ${(props) => (props.issp ? `80px` : ``)};
`;

const TextDiv = styled.div`
  text-align: center;
  margin-top: 44px;
  margin-bottom: 35px;
`;
const Text = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

const TimerDiv = styled.div`
  display: flex;
  gap: ${(props) => (props.issp ? `12px` : `41px`)};
  align-items: flex-end;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TimeSpan = styled.span`
  font-family: "Noto Sans JP", "sans-serif";
  font-size: ${(props) => (props.issp ? `1.25rem` : `3rem`)};
  font-weight: 700;
`;

const TimeTextSpan = styled.span`
  font-family: "Noto Sans JP", "sans-serif";
  font-size: ${(props) => (props.issp ? `0.875rem` : `1.5rem`)};
  font-weight: 700;
`;

const Box = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  width: ${(props) => (props.issp ? `80px` : `190px`)};
  height: ${(props) => (props.issp ? `80px` : `160px`)};
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.4);
`;

const BackGroundDiv = styled.div`
  margin-top: 48px;
`;

const BackGround = styled.img`
  display: block;
  width: 100%;
  height: auto;
  bottom: 0;
  position: fixed;
`;
export default Release;
